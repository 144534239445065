var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"4"}},[_c('asset-card-detail-data',{attrs:{"title":"Количество","value":_vm.intengerFormatter(_vm.data.quantity)}}),_c('asset-card-detail-data',{attrs:{"title":"Доля","value":_vm.perFormatter(_vm.data.share)}}),_c('asset-card-detail-data',{attrs:{"title":"Дата начала владения","value":_vm.dateFormatter(_vm.data.minTradeDate)}}),_c('asset-card-detail-data',{attrs:{"title":"Дата окончания владения","value":_vm.dateFormatter(_vm.data.maxTradeDate)}}),_c('asset-card-detail-data',{attrs:{"title":"Срок владения","value":_vm.diff_year_month_day(_vm.data.minTradeDate, _vm.data.maxTradeDate)}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('asset-card-detail-data',{attrs:{"title":"Текущая стоимость","value":_vm.curFormatter(_vm.data.currentCost, _vm.currentCurrency)}}),_c('asset-card-detail-data',{attrs:{"title":"Средняя цена","value":_vm.curFormatter(_vm.data.avgPrice, _vm.currentCurrency)}}),_c('asset-card-detail-data',{attrs:{"title":"Комиссии","value":_vm.curFormatter(_vm.data.commission, _vm.currentCurrency)}}),_c('asset-card-detail-data',{attrs:{"title":"Сумма покупок","value":_vm.curFormatter(_vm.data.costBuy, _vm.currentCurrency)}}),_c('asset-card-detail-data',{attrs:{"title":"Сумма продаж","value":_vm.curFormatter(_vm.data.costSell, _vm.currentCurrency)}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('asset-card-detail-data',{attrs:{"title":"Дивиденды/купоны","value":((_vm.curFormatter(_vm.data.divTotal, _vm.currentCurrency)) + " (" + (_vm.perFormatter(
        _vm.data.divTotalPercent
      )) + ")")}}),_c('asset-card-detail-data',{attrs:{"title":"Курсовая прибыль","value":((_vm.curFormatter(_vm.data.incomeOpens, _vm.currentCurrency)) + " (" + (_vm.perFormatter(
        _vm.data.incomeOpensPercent
      )) + ")")}}),_c('asset-card-detail-data',{attrs:{"title":"Прибыль по сделкам","value":((_vm.curFormatter(_vm.data.incomeFromSells, _vm.currentCurrency)) + " (" + (_vm.perFormatter(
        _vm.data.incomeFromSellsPercent
      )) + ")")}}),_c('asset-card-detail-data',{attrs:{"title":"Прибыль","value":((_vm.curFormatter(_vm.data.incomeTotal, _vm.currentCurrency)) + " (" + (_vm.perFormatter(
        _vm.data.incomeTotalPercent
      )) + ")")}}),_c('asset-card-detail-data',{attrs:{"title":"Доходность","value":_vm.perFormatter(_vm.data.yeld)}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }