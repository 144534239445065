<template>
  <div>
    <!-- HEADER -->
    <div class="row breadcrumbs-top mb-2 col-12">
      <h2 class="content-header-title float-left pr-1 mb-0">{{ securityData.securityName }}</h2>
      <b-breadcrumb class="breadcrumb-slash">
        <b-breadcrumb-item @click="returnBack">
          <div>
            <feather-icon icon="ArrowLeftIcon" />
            Назад
          </div>
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <!-- DATA -->
    <div class="row">
      <div class="col-6">
        <b-card>
          <div class="mb-1">
            <h4 class="mb-0">{{ securityData.securityNameFull }}</h4>
            <span class="text-muted">
              <small>{{ securityData.securityCode }}</small>
              <small> • </small>
              <small>{{ securityData.securityIsin }}</small>
              <small> • </small>
              <small>{{ securityData.boardCode }}</small>
            </span>
          </div>
          <h2>{{ curFormatter(securityData.currentPrice, currentCurrency) }}</h2>
        </b-card>
      </div>
      <div class="col-6">
        <b-card>
          <div>
            <asset-card-detail-data title="Эмитент" :value="securityData.companyName" />
            <asset-card-detail-data title="Вид" :value="securityData.groupName" />
            <asset-card-detail-data title="Тип" :value="securityData.typeName" />
          </div>
        </b-card>
      </div>
    </div>
    <!-- <b-card title="История цены"></b-card> -->
    <b-card title="В портфеле">
      <asset-card-detail :data="securityData"></asset-card-detail>
    </b-card>
    <!-- <b-card title="Открытые сделки">
      <b-table
        class="position-relative"
        ref="refOpenTransTable"
        :items="securityData.openTransaction"
        :fields="fields"
        responsive
        primary-key="id"
        show-empty
        empty-text="Сделки отсутствуют"
        small
      >
        <template #cell(taxLDV)="data">
          <feather-icon :icon="data.value ? 'CheckIcon' : ''" class="text-success" />
        </template>
      </b-table>
    </b-card> -->
  </div>
</template>

<script>
import { asCurrency, asIntenger, asDate } from "@/utils/formatter";

import AssetCardDetail from "@/components/investments/portfolioAssetsCard/AssetCardDetail.vue";
import AssetCardDetailData from "@/components/investments/portfolioAssetsCard/AssetCardDetailData.vue";

export default {
  name: "AssetDetail",
  components: {
    AssetCardDetail,
    AssetCardDetailData,
  },
  data() {
    return {
      fields: [
        {
          key: "date",
          label: "Дата",
          formatter: (val) => asDate(val),
        },
        {
          key: "quantity",
          label: "Количество",
          formatter: (val) => asIntenger(val),
        },
        {
          key: "total",
          label: "Стоимость покупки",
          formatter: (val) => asCurrency(parseFloat(val), this.currentCurrency),
        },
        {
          key: "tranCost",
          label: "Тек. стоимость",
          formatter: (val) => asCurrency(parseFloat(val), this.currentCurrency),
        },
        {
          key: "tranProfit",
          label: "Доход",
          formatter: (val) => asCurrency(parseFloat(val), this.currentCurrency),
        },
        {
          key: "tranTax",
          label: "Налог",
          formatter: (val) => asCurrency(parseFloat(val), this.currentCurrency),
        },
        {
          key: "dayHold",
          label: "Дней владения",
          formatter: (val) => asIntenger(val),
        },
        {
          key: "taxLDV",
          label: "ЛДВ",
        },
      ],
    };
  },
  computed: {
    securityData() {
      return this.$store.getters["investments/curSecurityDetail"];
    },
    currentCurrency() {
      return this.$store.getters["investments/curCurrency"].value;
    },
  },
  methods: {
    returnBack() {
      this.$store.commit("investments/SET_SELECT_SECURITY_DETAIL", null);
    },
    curFormatter: (val, cur) => asCurrency(val, cur),
  },
  destroyed() {
    this.$store.commit("investments/SET_SELECT_SECURITY_DETAIL", null);
  },
};
</script>

<style></style>
