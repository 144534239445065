<template>
  <dl class="row my-0">
    <dt class="col-sm-6">{{ title }}:</dt>
    <dd class="col-sm-6">{{ value }}</dd>
  </dl>
</template>

<script>
export default {
  name: "AssetCardDetailData",
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>
