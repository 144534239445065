<template>
  <b-row>
    <b-col lg="3" sm="6">
      <statistic-card-horizontal
        icon="DollarSignIcon"
        :statistic="curFormatter(data.currentCost, currentCurrency)"
        statistic-title="Стоимость"
      />
    </b-col>
    <b-col lg="3" sm="6">
      <statistic-card-horizontal
        icon="TrendingUpIcon"
        color="success"
        :statistic="curFormatter(data.incomeTotal, currentCurrency)"
        statistic-title="Прибыль"
      />
    </b-col>
    <b-col lg="3" sm="6">
      <statistic-card-horizontal
        icon="PercentIcon"
        color="warning"
        :statistic="curFormatter(data.incomeDivsYtd, currentCurrency)"
        statistic-title="Дивиденды/купоны, YTD"
      />
    </b-col>
    <b-col lg="3" sm="6">
      <statistic-card-horizontal
        icon="ActivityIcon"
        color="danger"
        :statistic="perFormatter(data.annualYeldCash)"
        statistic-title="Доходность"
      />
    </b-col>
  </b-row>
</template>

<script>
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import { asCurrency, asPercent, asDecimal } from "@/utils/formatter";

export default {
  name: "PortfolioDashboard",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    StatisticCardHorizontal,
  },
  computed: {
    currentCurrency() {
      return this.$store.getters["investments/curCurrency"].value;
    },
  },
  methods: {
    perFormatter(val) {
      return asPercent(val * 100, 2, 2);
    },
    curFormatter(val, cur) {
      return asCurrency(val, cur);
    },
    decFormatter(val) {
      return asDecimal(val);
    },
  },
};
</script>

<style></style>
