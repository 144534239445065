<template>
  <v-chart class="chart" :option="option" autoresize />
</template>

<script>
import { THEME_KEY } from 'vue-echarts';

export default {
  provide: {
    [THEME_KEY]: 'shine',
  },
  props: {
    title: {
      type: Object,
      default: null,
    },
    dataSeries: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      option: {
        title: this.title,
        tooltip: {
          trigger: 'item',
          formatter(params) {
            const val = params.value.toLocaleString('ru-RU', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
            return `${params.name}: ${val} (${params.percent}%)`;
          },
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          left: 'left',
          top: 'top',
          textStyle: {
            color: '#6e6b7b', // FIXME: Сделать динамический
          },
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            label: {
              show: false,
            },
            labelLine: {
              show: true,
            },
            data: this.dataSeries,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      },
    };
  },
  watch: {
    dataSeries(newVal) {
      this.option.series[0].data = newVal;
    },
  },
};
</script>
