const locale = (navigator && navigator.language) || "ru-RU";

export function asDate(val) {
  if (!val) return null;
  return new Date(val).toLocaleDateString(locale);
}

export function asDateTime(val) {
  if (!val) return null;
  return new Date(val).toLocaleString(locale);
}

export function asIntenger(val) {
  // if (val is null) return null;

  const newVal = Number(val);
  return newVal.toLocaleString(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

export function asDecimal(val, minDig = 2, maxDig = 2) {
  if (!val) return null;

  const newVal = Number(val);
  return newVal.toLocaleString(locale, {
    minimumFractionDigits: minDig,
    maximumFractionDigits: maxDig,
  });
}

export const asPercent = (val, minDig = 2, maxDig = 4) => {
  let nv = val || 0;
  nv = nv.toLocaleString(locale, {
    minimumFractionDigits: minDig,
    maximumFractionDigits: maxDig,
  });
  return `${nv}%`;
};

export const asCurrency = (val, cur) => {
  if (!val && !cur) return "-";

  const nv = val || 0;
  return nv.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: cur,
  });
};

//----------------

export const percentFormatter = (val, minDig = 2, maxDig = 4) => {
  let nv = val || 0;
  nv = nv.toLocaleString(locale, {
    minimumFractionDigits: minDig,
    maximumFractionDigits: maxDig,
  });
  return `${nv}%`;
};

export const intengerFormatter = (val) => {
  const nv = val || 0;
  return nv.toLocaleString(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const decimalFormatter = (val, digits = 2) => {
  const nv = val || 0;
  return nv.toLocaleString(locale, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
};

export const currencyFormatter = (val, cur) => {
  const nv = val || 0;
  return nv.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: cur,
  });
};
