<template>
  <b-row class="mb-2">
    <b-col md="4">
      <asset-card-detail-data title="Количество" :value="intengerFormatter(data.quantity)" />
      <asset-card-detail-data title="Доля" :value="perFormatter(data.share)" />
      <asset-card-detail-data
        title="Дата начала владения"
        :value="dateFormatter(data.minTradeDate)"
      />
      <asset-card-detail-data
        title="Дата окончания владения"
        :value="dateFormatter(data.maxTradeDate)"
      />
      <asset-card-detail-data
        title="Срок владения"
        :value="diff_year_month_day(data.minTradeDate, data.maxTradeDate)"
      />
    </b-col>
    <b-col md="4">
      <asset-card-detail-data
        title="Текущая стоимость"
        :value="curFormatter(data.currentCost, currentCurrency)"
      />
      <!-- <asset-card-detail-data
        title="Вложено"
        :value="curFormatter(data.invested, currentCurrency)"
      /> -->
      <asset-card-detail-data
        title="Средняя цена"
        :value="curFormatter(data.avgPrice, currentCurrency)"
      />
      <asset-card-detail-data
        title="Комиссии"
        :value="curFormatter(data.commission, currentCurrency)"
      />
      <!-- <asset-card-detail-data
        title="Налоги"
        :value="curFormatter(data.divTaxes, currentCurrency)"
      /> -->
      <asset-card-detail-data
        title="Сумма покупок"
        :value="curFormatter(data.costBuy, currentCurrency)"
      />
      <asset-card-detail-data
        title="Сумма продаж"
        :value="curFormatter(data.costSell, currentCurrency)"
      />
    </b-col>
    <b-col md="4">
      <asset-card-detail-data
        title="Дивиденды/купоны"
        :value="`${curFormatter(data.divTotal, currentCurrency)} (${perFormatter(
          data.divTotalPercent,
        )})`"
      />
      <asset-card-detail-data
        title="Курсовая прибыль"
        :value="`${curFormatter(data.incomeOpens, currentCurrency)} (${perFormatter(
          data.incomeOpensPercent,
        )})`"
      />
      <asset-card-detail-data
        title="Прибыль по сделкам"
        :value="`${curFormatter(data.incomeFromSells, currentCurrency)} (${perFormatter(
          data.incomeFromSellsPercent,
        )})`"
      />
      <asset-card-detail-data
        title="Прибыль"
        :value="`${curFormatter(data.incomeTotal, currentCurrency)} (${perFormatter(
          data.incomeTotalPercent,
        )})`"
      />
      <asset-card-detail-data title="Доходность" :value="perFormatter(data.yeld)" />
    </b-col>
  </b-row>
</template>

<script>
import AssetCardDetailData from "@/components/investments/portfolioAssetsCard/AssetCardDetailData.vue";
import { asPercent, asCurrency, asDecimal, asIntenger, asDate } from "@/utils/formatter";

export default {
  name: "AssetCardDetail",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    AssetCardDetailData,
  },
  computed: {
    currentCurrency() {
      return this.$store.getters["investments/curCurrency"].value;
    },
  },
  methods: {
    perFormatter: (val) => asPercent(val * 100, 2, 2),
    curFormatter: (val, cur) => asCurrency(val, cur),
    decimalFormatter: (val) => asDecimal(val),
    intengerFormatter: (val) => asIntenger(val),
    dateFormatter: (val) => asDate(val),
    diff_year_month_day(dateStart, dateEnd) {
      if (!dateStart) return "-";

      const dtStart = new Date(dateStart);
      const dtEnd = new Date(dateEnd);
      const timeDiff = (dtEnd.getTime() - dtStart.getTime()) / 1000;
      // const yearDiff = Math.abs(Math.round(timeDiff / (60 * 60 * 24) / 365.25));
      // const monthDiff = Math.abs(Math.round(timeDiff / (60 * 60 * 24 * 7 * 4)));
      const daysDiff = Math.abs(Math.round(timeDiff / (3600 * 24)));

      return `${daysDiff} дней`;
      // 'Year :- ' + yearDiff + ' Month :- ' + monthDiff + ' Days :-' + daysDiff;
    },
  },
};
</script>

<style></style>
