<template>
  <!-- FIXME: автоматическое обновление collapsed -->
  <b-card-actions :title="title" action-collapse :collapsed="!data.length">
    <b-form-checkbox
      class="custom-control-primary mb-2"
      name="check-button"
      switch
      v-model="filter.innerValue"
    >
      <span class="switch-icon-left">
        <feather-icon icon="EyeOffIcon" />
      </span>
      <span class="switch-icon-right">
        <feather-icon icon="EyeIcon" />
      </span>
    </b-form-checkbox>

    <b-table
      :fields="fields"
      :items="data"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :filter="filter"
      :filter-function="filterSold"
      small
      responsive="sm"
    >
      <!-- Cell template -->
      <template #cell(incomeTotal)="data">
        <span :class="+data.value > 0 ? 'text-success' : 'text-danger'">
          {{ curFormatter(data.value, currentCurrency) }}
        </span>
      </template>
      <template #cell(incomeTotalPercent)="data">
        <span :class="+data.value > 0 ? 'text-success' : 'text-danger'">
          {{ data.value | percent }}
        </span>
      </template>
      <template #cell(yeld)="data">
        <span :class="+data.value > 0 ? 'text-success' : 'text-danger'">
          {{ data.value | percent }}
        </span>
      </template>

      <!-- Row details -->
      <template #cell(show_details)="row">
        <feather-icon icon="EyeIcon" @click="showSecurityData(row.item)" />
      </template>
    </b-table>
  </b-card-actions>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

import { asDecimal, asPercent, asCurrency } from "@/utils/formatter";

export default {
  name: "AssetCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    soldFilterValue: {
      type: Boolean,
    },
  },
  components: {
    BCardActions,
  },
  filters: {
    decimal(value) {
      return asDecimal(value);
    },
    percent(value) {
      return asPercent(value * 100, 2, 2);
    },
  },
  data() {
    return {
      sortBy: "sharePer",
      sortDesc: true,
      filter: {
        innerValue: false,
      },
    };
  },
  computed: {
    currentCurrency() {
      return this.$store.getters["investments/curCurrency"].value;
    },
  },
  watch: {
    "filter.innerValue": function () {
      this.$emit("setSoldFilter", this.filter.innerValue);
    },
  },
  methods: {
    filterSold(row, val) {
      const { innerValue: soldView } = val;

      return soldView ? row.quantity !== 0 : true;
    },
    curFormatter: (val, cur) => asCurrency(val, cur),
    showSecurityData(row) {
      this.$store.commit("investments/SET_SELECT_SECURITY_DETAIL", row);
    },
  },
  created() {
    if (this.soldFilterValue) this.filter.innerValue = this.soldFilterValue;
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
