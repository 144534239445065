<template>
  <section id="portfolio-overview">
    <b-overlay :show="showOverlay" class="mb-3">
      <template v-if="dataLoaded">
        <template v-if="selectedSecurityDetail">
          <asset-detail />
        </template>
        <template v-else>
          <portfolio-dashboard :data="overviewData.common" />
          <portfolio-cash :data="overviewData.cash" />
          <asset-card
            id="assets"
            title="Активы"
            :fields="fields"
            :data="overviewData.assets"
            :soldFilterValue="this.$store.state.investments.soldFilters.shares"
            @setSoldFilter="setSoldFilterShares"
          />
          <b-row>
            <b-col md="6" sm="12">
              <chart-assets :data="chartAssetsData" title="Состав по активам" />
            </b-col>
            <b-col md="6" sm="12" v-if="chartSharesData.length">
              <chart-assets :data="chartSharesData" title="Акции" />
            </b-col>
            <b-col md="6" sm="12" v-if="chartBondsData.length">
              <chart-assets :data="chartBondsData" title="Облигации" />
            </b-col>
            <b-col md="6" sm="12" v-if="chartEtfsData.length">
              <chart-assets :data="chartEtfsData" title="ETF" />
            </b-col>
          </b-row>
        </template>
      </template>
    </b-overlay>
  </section>
</template>

<script>
import { ref, onBeforeMount, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import PortfolioDashboard from "@/components/investments/PortfolioDashboard.vue";
import PortfolioCash from "@/components/investments/PortfolioCash.vue";
import AssetCard from "@/components/investments/portfolioAssetsCard/AssetCard.vue";
import AssetDetail from "@/components/investments/portfolioAssetsCard/AssetDetail.vue";
import ChartAssets from "@/components/investments/charts/ChartAssets.vue";

import usePortfolio from "@/comp-functions/usePortfolio";
import { getPortfolioOwerview } from "@/api/investments";
import { asIntenger, asPercent, asCurrency } from "@/utils/formatter";

import store from "@/store";

export default {
  name: "Overview",
  components: {
    PortfolioDashboard,
    PortfolioCash,
    AssetCard,
    ChartAssets,
    AssetDetail,
  },
  setup() {
    const toast = useToast();

    const showOverlay = ref(false);
    const dataLoaded = ref(false);

    const { currentPortfolio, updateData, currentCurrency, selectedSecurityDetail } =
      usePortfolio();

    const overviewData = ref({});
    const chartAssetsData = ref([]);
    const chartSharesData = ref([]);
    const chartBondsData = ref([]);
    const chartEtfsData = ref([]);

    // Charts
    const groupingChart = (chartData) =>
      Array.from(
        chartData.reduce(
          (m, { name, value }) => m.set(name, (m.get(name) || 0) + value),
          new Map(),
        ),
        ([name, value]) => ({ name, value }),
      );
    const updateCharts = () => {
      chartAssetsData.value = [];
      chartSharesData.value = [];
      chartBondsData.value = [];
      chartEtfsData.value = [];

      if (overviewData.value.assets) {
        overviewData.value.assets.forEach((x) => {
          if (x.currentCost > 0) {
            const assetName = x.securityName;
            const assetCost = x.currentCost;
            const secGroup = x.groupCode;
            const secGroupName = x.groupName;
            // Assets
            chartAssetsData.value.push({
              name: secGroupName,
              value: Math.round(assetCost),
            });

            switch (secGroup) {
              case "shares":
                chartSharesData.value.push({
                  name: assetName,
                  value: Math.round(assetCost),
                });
                break;
              case "bonds":
                chartBondsData.value.push({
                  name: assetName,
                  value: Math.round(assetCost),
                });
                break;
              case "etfs":
                chartEtfsData.value.push({
                  name: assetName,
                  value: Math.round(assetCost),
                });
                break;
              default:
                break;
            }
          }
        });
        chartAssetsData.value = groupingChart(chartAssetsData.value);
      }
    };

    const updateOverviewData = async () => {
      showOverlay.value = true;
      dataLoaded.value = false;
      try {
        overviewData.value = await getPortfolioOwerview(
          currentPortfolio.value.id,
          currentCurrency.value.value,
        );

        dataLoaded.value = true;
        updateCharts();
      } catch (err) {
        console.error(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Ошибка загрузки данных",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }

      showOverlay.value = false;
    };
    onBeforeMount(async () => {
      await updateOverviewData();
    });
    watch([currentPortfolio, currentCurrency, updateData], () => {
      updateOverviewData();
    });

    // Field portfolio-asset-card
    const fields = [
      {
        key: "show_details",
        label: "",
      },
      {
        key: "securityName",
        label: "Название",
      },
      {
        key: "quantity",
        label: "Количество",
        formatter: (val) => asIntenger(val),
      },
      {
        key: "currentCost",
        label: "Тек. стоимость",
        sortable: true,
        formatter: (val) => asCurrency(val, currentCurrency.value.value),
      },
      {
        key: "share",
        label: "Доля",
        sortable: true,
        formatter: (val) => asPercent(val * 100, 2, 2),
      },
      {
        key: "divTotal",
        label: "Дивиденды / купоны",
        sortable: true,
        formatter: (val) => asCurrency(val, currentCurrency.value.value),
      },
      {
        key: "divTotalPercent",
        label: "Дивиденды / купоны, %",
        sortable: true,
        formatter: (val) => asPercent(val * 100, 2, 2),
      },
      {
        key: "incomeTotal",
        label: "Прибыль",
        sortable: true,
      },
      {
        key: "incomeTotalPercent",
        label: "Прибыль, %",
        sortable: true,
      },
      {
        key: "yeld",
        label: "Доходность, %",
        sortable: true,
      },
    ];

    // Filter sold assets
    const setSoldFilterShares = (val) => {
      store.commit("investments/SET_SOLD_FILTER_SHARES", val);
    };

    return {
      showOverlay,
      dataLoaded,
      overviewData,
      selectedSecurityDetail,
      fields,

      // Charts
      // totalCharts,
      chartAssetsData,
      chartSharesData,
      chartBondsData,
      chartEtfsData,

      // Filtres
      setSoldFilterShares,
    };
  },
};
</script>

<style></style>
