<template>
  <b-card :title="this.title">
    <echart-doughnut :dataSeries="data" />
  </b-card>
</template>

<script>
import EchartDoughnut from '@/components/charts/EchartDoughnut.vue';

export default {
  name: 'ChartAssets',
  components: {
    EchartDoughnut,
  },
  props: {
    data: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 250px;
}
</style>
