<template>
  <b-card title="Валюта">
    <b-table
      :fields="fields"
      :items="data"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      small
      responsive="sm"
    >
    </b-table>
  </b-card>
</template>

<script>
import { asPercent, asCurrency } from "@/utils/formatter";

export default {
  name: "PortfolioCash",
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "currencyName",
          label: "Название",
        },
        {
          key: "balance",
          label: "Баланс",
          formatter: (val, key, item) => asCurrency(val, item.currencyCode),
        },
        {
          key: "total",
          label: "Стоимость",
          formatter: (val) => asCurrency(val, this.$store.getters["investments/curCurrency"].value),
        },
        {
          key: "share",
          label: "Текущая доля, %",
          sortable: true,
          formatter: (val) => asPercent(val * 100, 2, 2),
        },
      ],
      sortBy: "sharePortfolio",
      sortDesc: true,
    };
  },
};
</script>

<style></style>
